import React from "react";
import { Route } from "react-router-dom";
import QuickDoc from "../views/Quick";
import QuickIntroduction from "../views/Quick/Introduction";
import QuickBasic from "../views/Quick/Basic";
import QuickServer from "../views/Quick/Server";
import QuickFront from "../views/Quick/front";
import QuickAdmin from "../views/Quick/admin";
import Recommendations from "../views/Quick/recommendations";
import QuickLocalFront from "../views/Quick/local-front";
import QuickMobileApp from "../views/Quick/Mobile-app";
import QuickFlutterSDK from "../views/Quick/Flutter-SDK";
import QuickLocalServer from "../views/Quick/Local-server";
import QuickInstallOnServer from "../views/Quick/Install-on-server";
import QuickMandatorySetup from "../views/Quick/Mandatory-setup";
import QuickCustomization from "../views/Quick/Customization";
import QuickMandatorySetupMobile from "../views/Quick/Mandatory-setup-mobile";
import QuickMandatorySetupBackend from "../views/Quick/Mandatory-setup-backend";
import PaymentInstallation from "../views/Quick/Payment-Installation";
import QuickImageSettings from "../views/Quick/image-settings";
import QuickMandatorySetupVendor from "../views/Quick/Moderator-setup-vendor";
import QuickMandatorySetupCustomer from "../views/Quick/Moderator-setup-customer";
import QuickMandatorySetupPos from "../views/Quick/Moderator-setup-pos";
import QuickMandatorySetupDeliveryboy from "../views/Quick/Moderator-setup-deliveryboy";
import QuickCustomizationMobile from "../views/Quick/Customization-mobile";
import CustomizationMobileDelivery from "../views/Foodyman-doc/Customization-mobile-delivery";
import QuickCustomizationMobileCustomer from "../views/Quick/Customization-mobile-customer";
import CustomizationMobilePos from "../views/Quick/Customization-mobile-pos";
import AppBuildReleaseCustomer from "../views/Quick/App-build-release-customer";
import AppBuildReleasePos from "../views/Quick/App-build-release-pos";
import AppBuildReleaseVendor from "../views/Quick/App-build-release-vendor";
import AppBuildReleaseDelivery from "../views/Foodyman-doc/App-build-release-deliveryboy";
import QuickMandatorySetupWeb from "../views/Quick/Mandatory-setup-web";
import QuickBuildCodeAndSetupOnServer from "../views/Quick/Build-code-and-setup-on-server";
import QuickBuildCodeAndSetupOnServerBackend from "../views/Quick/Build-code-and-setup-on-server-backend";
import QuickUpdateAdminPanel from "../views/Quick/Update-admin-panel";
import QuickUpdateAppWeb from "../views/Quick/Update-app-web";
import QuickSupportPlan from "../views/Quick/supportPlan";
import QuickFirebaseSetup from "../views/Quick/firebase-setup";
import TroubleshootingBackend from "../views/Quick/Troubleshooting-backend";
import TroubleshootingAdmin from "../views/Quick/Troubleshooting-admin";
import UpdateFooyman from "../views/Quick/update";
import MobileVendor from "../views/Quick/Mobile-Vendor";
import WebFirebase from "../views/Quick/web-firebase";
import MobileFirebase from "../views/Quick/mobile-firebase";

const Quick = () => {
  return (
    <Route path="/quick" element={<QuickDoc />}>
      <Route path="/quick/introduction" element={<QuickIntroduction />} />
      <Route path="/quick/basic" element={<QuickBasic />} />
      <Route path="/quick/server" element={<QuickServer />} />
      <Route path="/quick/front" element={<QuickFront />} />
      <Route path="/quick/admin" element={<QuickAdmin />} />
      <Route path="/quick/recommendations" element={<Recommendations />} />
      <Route path="/quick/local-front" element={<QuickLocalFront />} />
      <Route path="/quick/mobile-app" element={<QuickMobileApp />} />
      <Route
        path="/quick/vendor-app"
        element={<MobileVendor pageTitle="Seller and Driver app" />}
      />
      <Route path="/quick/driver-app" element={<MobileVendor />} />
      <Route
        path="/quick/pos-app"
        element={<MobileVendor pageTitle="Pos App" />}
      />
      <Route path="/quick/flutter-sdk" element={<QuickFlutterSDK />} />
      <Route path="/quick/local-server" element={<QuickLocalServer />} />
      <Route
        path="/quick/install-on-server"
        element={<QuickInstallOnServer />}
      />
      <Route
        path="/quick/mandatory-setup"
        element={<QuickMandatorySetup />}
      />
      <Route
        path="/quick/customization"
        element={<QuickCustomization />}
      />
      <Route
        path="/quick/mandatory-setup-mobile"
        element={<QuickMandatorySetupMobile />}
      />
      <Route
        path="/quick/mandatory-setup-backend"
        element={<QuickMandatorySetupBackend />}
      />
      <Route
        path="/quick/payment-installation"
        element={<PaymentInstallation />}
      />
      <Route
        path="/quick/image-settings"
        element={<QuickImageSettings />}
      />
      <Route
        path="/quick/mandatory-setup-vendor"
        element={<QuickMandatorySetupVendor />}
      />
      <Route
        path="/quick/mandatory-setup-customer"
        element={<QuickMandatorySetupCustomer />}
      />
      <Route
        path="/quick/mandatory-setup-pos"
        element={<QuickMandatorySetupPos />}
      />
      <Route
        path="/quick/mandatory-setup-deliveryboy"
        element={<QuickMandatorySetupDeliveryboy />}
      />
      <Route
        path="/quick/customization-vendor"
        element={<QuickCustomizationMobile />}
      />
      <Route
        path="/quick/customization-deliveryboy"
        element={<CustomizationMobileDelivery />}
      />
      <Route
        path="/quick/customization-customer"
        element={<QuickCustomizationMobileCustomer />}
      />
      <Route
        path="/quick/customization-pos"
        element={<CustomizationMobilePos />}
      />
      <Route
        path="/quick/customer-app-build-release"
        element={<AppBuildReleaseCustomer />}
      />
      <Route
        path="/quick/pos-app-build-release"
        element={<AppBuildReleasePos />}
      />
      <Route
        path="/quick/vendor-app-build-release"
        element={<AppBuildReleaseVendor />}
      />
      <Route
        path="/quick/deliveryboy-app-build-release"
        element={<AppBuildReleaseDelivery />}
      />
      <Route
        path="/quick/mandatory-setup-web"
        element={<QuickMandatorySetupWeb />}
      />
      <Route
        path="/quick/build-code-and-setup-on-server"
        element={<QuickBuildCodeAndSetupOnServer />}
      />
      <Route
        path="/quick/build-code-and-setup-on-server-backend"
        element={<QuickBuildCodeAndSetupOnServerBackend />}
      />
      <Route
        path="/quick/update-admin-panel"
        element={<QuickUpdateAdminPanel />}
      />
      <Route
        path="/quick/update-app-web"
        element={<QuickUpdateAppWeb />}
      />
      <Route path="/quick/support-plan" element={<QuickSupportPlan />} />
      <Route path="/quick/firebase" element={<QuickFirebaseSetup />} />

      <Route
        path="/quick/troubleshooting-backend"
        element={<TroubleshootingBackend />}
      />
      <Route
        path="/quick/troubleshooting-admin"
        element={<TroubleshootingAdmin />}
      />
      <Route path="/quick/update" element={<UpdateFooyman />} />

      <Route path="/quick/firebase-web" element={<WebFirebase />} />
      <Route path="/quick/firebase-mobile" element={<MobileFirebase />} />
    </Route>
  );
};

export default Quick;
