import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

const Introduction = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <div className="alert alert-primary mb-5" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Keep /configs/credential.php and /configs/init.php file while updating.
        If you don’t keep them, you may have a problem with lisence.
      </div>

      <h1 className="title">Introduction</h1>

      <strong className="introduction-subTitle">
        Build Your Business with our -
        <a
          href="https://quick-admin.uzmart.org"
          target="_blank"
          className="strong"
        >
          {" "}
          Admin panel
        </a>
      </strong>
      <br />

      <strong className="introduction-subTitle">
        Build Your Business with our -
        <a
          href="https://testflight.apple.com/join/cD6aq8mG"
          target="_blank"
          className="strong"
        >
          {" "}
          IOS Customer app
        </a>
        ,
        <a
          href="https://drive.google.com/file/d/1Qt77k4eXPVRZHbl8rJpOIsd-K3MRLzU1/view?usp=drive_link"
          target="_blank"
          className="strong"
        >
          {" "}
          Android Customer app
        </a>
      </strong>
      <br />
      <br />

      <div>
        Quick is a multi vendor digital product, full solution ( apps, website
        is coming soon, admin) developed by applying Laravel and Flutter
        Frameworks. <br />
        Laravel is an open-source PHP Framework intended for the development of
        web applications which is a complete toolset, robust, yet easy to
        understand with an excellent, expressive syntax.
        <br />
        Flutter is an open-source framework for mobile application development
        created by Google. It is applied to develop multi-platform, natively
        compiled applications for Android and iOS.
        <div className="line-break" />
        Quick consists of Mobile Application, Web Panel:
        <br />
        <div className="introduction-img-container">
          <SimpleReactLightbox>
            <SRLWrapper>
              <a
                href="/assets/img/doc/quick-admin-banner.png"
                data-fancybox
                rel="nofollow"
              >
                <img
                  src="/assets/img/doc/quick-admin-banner.png"
                  alt="admin"
                  loading="lazy"
                  className="img-responsive-full"
                />
              </a>
            </SRLWrapper>
          </SimpleReactLightbox>
          <SimpleReactLightbox>
            <SRLWrapper>
              <a
                href="/assets/img/project/quick-banner.png"
                data-fancybox
                rel="nofollow"
              >
                <img
                  src="/assets/img/project/quick-banner.png"
                  alt="web main"
                  loading="lazy"
                  className="img-responsive-full"
                  style={{ minWidth: "20vw" }}
                />
              </a>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
        <div className="line-break" />
        <h3 className="introduction-contentTitle">
          Mobile App developed using Flutter
        </h3>
        <h3 className="introduction-contentTitle">
          Admin panel developed using Laravel & React Js
        </h3>
        <div className="line-break" />
      </div>

      <NavigationBtns />
    </div>
  );
};

export default Introduction;
