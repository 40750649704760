import React from "react";
import { Route } from "react-router-dom";
import Demand24Doc from "../views/Demand24-doc";
import Demand24Introduction from "../views/Demand24-doc/Introduction";
import Demand24Basic from "../views/Demand24-doc/Basic";
import Demand24Server from "../views/Demand24-doc/Server";
import Demand24Front from "../views/Demand24-doc/front";
import Demand24Admin from "../views/Demand24-doc/admin";
import Recommendations from "../views/Demand24-doc/recommendations";
import Demand24LocalFront from "../views/Demand24-doc/local-front";
import Demand24MobileApp from "../views/Demand24-doc/Mobile-app";
import Demand24FlutterSDK from "../views/Demand24-doc/Flutter-SDK";
import Demand24LocalServer from "../views/Demand24-doc/Local-server";
import Demand24InstallOnServer from "../views/Demand24-doc/Install-on-server";
import Demand24MandatorySetup from "../views/Demand24-doc/Mandatory-setup";
import Demand24Customization from "../views/Demand24-doc/Customization";
import Demand24MandatorySetupMobile from "../views/Demand24-doc/Mandatory-setup-mobile";
import Demand24MandatorySetupBackend from "../views/Demand24-doc/Mandatory-setup-backend";
import PaymentInstallation from "../views/Demand24-doc/Payment-Installation";
import Demand24ImageSettings from "../views/Demand24-doc/image-settings";
import Demand24MandatorySetupVendor from "../views/Demand24-doc/Moderator-setup-vendor";
import Demand24MandatorySetupCustomer from "../views/Demand24-doc/Moderator-setup-customer";
import Demand24MandatorySetupPos from "../views/Demand24-doc/Moderator-setup-pos";
import Demand24MandatorySetupDeliveryboy from "../views/Demand24-doc/Moderator-setup-deliveryboy";
import Demand24CustomizationMobile from "../views/Demand24-doc/Customization-mobile";
import CustomizationMobileDelivery from "../views/Foodyman-doc/Customization-mobile-delivery";
import Demand24CustomizationMobileCustomer from "../views/Demand24-doc/Customization-mobile-customer";
import CustomizationMobilePos from "../views/Demand24-doc/Customization-mobile-pos";
import AppBuildReleaseCustomer from "../views/Demand24-doc/App-build-release-customer";
import AppBuildReleasePos from "../views/Demand24-doc/App-build-release-pos";
import AppBuildReleaseVendor from "../views/Demand24-doc/App-build-release-vendor";
import AppBuildReleaseDelivery from "../views/Foodyman-doc/App-build-release-deliveryboy";
import Demand24MandatorySetupWeb from "../views/Demand24-doc/Mandatory-setup-web";
import Demand24BuildCodeAndSetupOnServer from "../views/Demand24-doc/Build-code-and-setup-on-server";
import Demand24BuildCodeAndSetupOnServerBackend from "../views/Demand24-doc/Build-code-and-setup-on-server-backend";
import Demand24UpdateAdminPanel from "../views/Demand24-doc/Update-admin-panel";
import Demand24UpdateAppWeb from "../views/Demand24-doc/Update-app-web";
import Demand24SupportPlan from "../views/Demand24-doc/supportPlan";
import Demand24FirebaseSetup from "../views/Demand24-doc/firebase-setup";
import TroubleshootingBackend from "../views/Demand24-doc/Troubleshooting-backend";
import TroubleshootingAdmin from "../views/Demand24-doc/Troubleshooting-admin";
import UpdateFooyman from "../views/Demand24-doc/update";
import MobileVendor from "../views/Demand24-doc/Mobile-vendor";
import MobileDriver from "../views/Demand24-doc/Mobile-driver";
import PaymentSetUp from "../views/Demand24-doc/Payment-set-up";

const Demand24 = () => {
  return (
    <Route path="/demand24-documentation" element={<Demand24Doc />}>
      <Route
        path="/demand24-documentation/introduction"
        element={<Demand24Introduction />}
      />
      <Route path="/demand24-documentation/basic" element={<Demand24Basic />} />
      <Route
        path="/demand24-documentation/server"
        element={<Demand24Server />}
      />
      <Route path="/demand24-documentation/front" element={<Demand24Front />} />
      <Route path="/demand24-documentation/admin" element={<Demand24Admin />} />
      <Route
        path="/demand24-documentation/recommendations"
        element={<Recommendations />}
      />
      <Route
        path="/demand24-documentation/local-front"
        element={<Demand24LocalFront />}
      />
      <Route
        path="/demand24-documentation/mobile-app"
        element={<Demand24MobileApp />}
      />
      <Route
        path="/demand24-documentation/vendor-app"
        element={<MobileVendor />}
      />
      <Route
        path="/demand24-documentation/driver-app"
        element={<MobileDriver />}
      />
      <Route
        path="/demand24-documentation/pos-app"
        element={<MobileVendor />}
      />
      <Route
        path="/demand24-documentation/flutter-sdk"
        element={<Demand24FlutterSDK />}
      />
      <Route
        path="/demand24-documentation/local-server"
        element={<Demand24LocalServer />}
      />
      <Route
        path="/demand24-documentation/install-on-server"
        element={<Demand24InstallOnServer />}
      />
      <Route
        path="/demand24-documentation/mandatory-setup"
        element={<Demand24MandatorySetup />}
      />
      <Route
        path="/demand24-documentation/customization"
        element={<Demand24Customization />}
      />
      <Route
        path="/demand24-documentation/mandatory-setup-mobile"
        element={<Demand24MandatorySetupMobile />}
      />
      <Route
        path="/demand24-documentation/mandatory-setup-backend"
        element={<Demand24MandatorySetupBackend />}
      />
      <Route
        path="/demand24-documentation/payment-installation"
        element={<PaymentInstallation />}
      />
      <Route
        path="/demand24-documentation/image-settings"
        element={<Demand24ImageSettings />}
      />
      <Route
        path="/demand24-documentation/mandatory-setup-vendor"
        element={<Demand24MandatorySetupVendor />}
      />
      <Route
        path="/demand24-documentation/mandatory-setup-driver"
        element={<Demand24MandatorySetupVendor />}
      />
      <Route
        path="/demand24-documentation/mandatory-setup-customer"
        element={<Demand24MandatorySetupCustomer />}
      />
      <Route
        path="/demand24-documentation/mandatory-setup-pos"
        element={<Demand24MandatorySetupPos />}
      />
      <Route
        path="/demand24-documentation/mandatory-setup-deliveryboy"
        element={<Demand24MandatorySetupDeliveryboy />}
      />
      <Route
        path="/demand24-documentation/customization-vendor"
        element={<Demand24CustomizationMobile />}
      />
      <Route
        path="/demand24-documentation/customization-driver"
        element={<Demand24CustomizationMobile />}
      />
      <Route
        path="/demand24-documentation/customization-deliveryboy"
        element={<CustomizationMobileDelivery />}
      />
      <Route
        path="/demand24-documentation/payment-setup"
        element={<PaymentSetUp />}
      />
      <Route
        path="/demand24-documentation/customization-customer"
        element={<Demand24CustomizationMobileCustomer />}
      />
      <Route
        path="/demand24-documentation/customization-pos"
        element={<CustomizationMobilePos />}
      />
      <Route
        path="/demand24-documentation/customer-app-build-release"
        element={<AppBuildReleaseCustomer />}
      />
      <Route
        path="/demand24-documentation/pos-app-build-release"
        element={<AppBuildReleasePos />}
      />
      <Route
        path="/demand24-documentation/vendor-app-build-release"
        element={<AppBuildReleaseVendor />}
      />
      <Route
        path="/demand24-documentation/driver-app-build-release"
        element={<AppBuildReleaseVendor />}
      />
      <Route
        path="/demand24-documentation/deliveryboy-app-build-release"
        element={<AppBuildReleaseDelivery />}
      />
      <Route
        path="/demand24-documentation/mandatory-setup-web"
        element={<Demand24MandatorySetupWeb />}
      />
      <Route
        path="/demand24-documentation/build-code-and-setup-on-server"
        element={<Demand24BuildCodeAndSetupOnServer />}
      />
      <Route
        path="/demand24-documentation/build-code-and-setup-on-server-backend"
        element={<Demand24BuildCodeAndSetupOnServerBackend />}
      />
      <Route
        path="/demand24-documentation/update-admin-panel"
        element={<Demand24UpdateAdminPanel />}
      />
      <Route
        path="/demand24-documentation/update-app-web"
        element={<Demand24UpdateAppWeb />}
      />
      <Route
        path="/demand24-documentation/support-plan"
        element={<Demand24SupportPlan />}
      />
      <Route
        path="/demand24-documentation/firebase"
        element={<Demand24FirebaseSetup />}
      />
      <Route
        path="/demand24-documentation/troubleshooting-backend"
        element={<TroubleshootingBackend />}
      />
      <Route
        path="/demand24-documentation/troubleshooting-admin"
        element={<TroubleshootingAdmin />}
      />
      <Route
        path="/demand24-documentation/update"
        element={<UpdateFooyman />}
      />
    </Route>
  );
};

export default Demand24;
