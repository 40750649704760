import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

const Demand24CustomizationMobileCustomer = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> Customization</h1>
      <h4 className="introduction-contentTitle">Translate mobile app</h4>
      • Translation admin panel is very easy. To translate admin panel, go to
      admin panel and open <br />
      <span className="introduction-step-2">
        Settings {">"} Translations menu
      </span>
      and Translate all words into your language
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/uzmarttranslation.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/uzmarttranslation.png"
                alt="img"
                className="img-responsive"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/uzmarttranslation1.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/uzmarttranslation1.png"
                alt="img"
                className="img-responsive"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <h4 className="introduction-contentTitle">Change App Color</h4>• Open{" "}
      <span className="introduction-step">
        /lib/presentation/style/style.dart
      </span>
      file and change colors as you want. <br />
      <div className="introduction-img-container">
        <img
          src="/assets/img/doc/color5.jpg"
          alt="img"
          className="img-responsive-full"
        />
      </div>
      <h4 className="introduction-contentTitle">Change App Font</h4>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/color6.jpeg" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/color6.jpeg"
                alt="img"
                className="img-responsive"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <NavigationBtns />
    </div>
  );
};

export default Demand24CustomizationMobileCustomer;
