import React from "react";
import { Route } from "react-router-dom";
import NewGoshopsDoc from "../views/New-go-shops";
import NewGoshopsIntroduction from "../views/New-go-shops/Introduction";
import NewGoshopsBasic from "../views/New-go-shops/Basic";
import NewGoshopsServer from "../views/New-go-shops/Server";
import NewGoshopsFront from "../views/New-go-shops/front";
import NewGoshopsAdmin from "../views/New-go-shops/admin";
import Recommendations from "../views/New-go-shops/recommendations";
import NewGoshopsLocalFront from "../views/New-go-shops/local-front";
import NewGoshopsMobileApp from "../views/New-go-shops/Mobile-app";
import NewGoshopsFlutterSDK from "../views/New-go-shops/Flutter-SDK";
import NewGoshopsLocalServer from "../views/New-go-shops/Local-server";
import NewGoshopsInstallOnServer from "../views/New-go-shops/Install-on-server";
import NewGoshopsMandatorySetup from "../views/New-go-shops/Mandatory-setup";
import NewGoshopsCustomization from "../views/New-go-shops/Customization";
import NewGoshopsMandatorySetupMobile from "../views/New-go-shops/Mandatory-setup-mobile";
import NewGoshopsMandatorySetupBackend from "../views/New-go-shops/Mandatory-setup-backend";
import PaymentInstallation from "../views/New-go-shops/Payment-Installation";
import NewGoshopsImageSettings from "../views/New-go-shops/image-settings";
import NewGoshopsMandatorySetupVendor from "../views/New-go-shops/Moderator-setup-vendor";
import NewGoshopsMandatorySetupCustomer from "../views/New-go-shops/Moderator-setup-customer";
import NewGoshopsMandatorySetupPos from "../views/New-go-shops/Moderator-setup-pos";
import NewGoshopsMandatorySetupDeliveryboy from "../views/New-go-shops/Moderator-setup-deliveryboy";
import NewGoshopsCustomizationMobile from "../views/New-go-shops/Customization-mobile";
import CustomizationMobileDelivery from "../views/Foodyman-doc/Customization-mobile-delivery";
import NewGoshopsCustomizationMobileCustomer from "../views/New-go-shops/Customization-mobile-customer";
import CustomizationMobilePos from "../views/New-go-shops/Customization-mobile-pos";
import AppBuildReleaseCustomer from "../views/New-go-shops/App-build-release-customer";
import AppBuildReleasePos from "../views/New-go-shops/App-build-release-pos";
import AppBuildReleaseVendor from "../views/New-go-shops/App-build-release-vendor";
import AppBuildReleaseDelivery from "../views/Foodyman-doc/App-build-release-deliveryboy";
import NewGoshopsMandatorySetupWeb from "../views/New-go-shops/Mandatory-setup-web";
import NewGoshopsBuildCodeAndSetupOnServer from "../views/New-go-shops/Build-code-and-setup-on-server";
import NewGoshopsBuildCodeAndSetupOnServerBackend from "../views/New-go-shops/Build-code-and-setup-on-server-backend";
import NewGoshopsUpdateAdminPanel from "../views/New-go-shops/Update-admin-panel";
import NewGoshopsUpdateAppWeb from "../views/New-go-shops/Update-app-web";
import NewGoshopsSupportPlan from "../views/New-go-shops/supportPlan";
import NewGoshopsFirebaseSetup from "../views/New-go-shops/firebase-setup";
import TroubleshootingBackend from "../views/New-go-shops/Troubleshooting-backend";
import TroubleshootingAdmin from "../views/New-go-shops/Troubleshooting-admin";
import UpdateFooyman from "../views/New-go-shops/update";
import MobileVendor from "../views/New-go-shops/Mobile-Vendor";
import WebFirebase from "../views/New-go-shops/web-firebase";
import MobileFirebase from "../views/New-go-shops/mobile-firebase";

const NewGoShops = () => {
  return (
    <Route path="/new-go-shops" element={<NewGoshopsDoc />}>
      <Route
        path="/new-go-shops/introduction"
        element={<NewGoshopsIntroduction />}
      />
      <Route path="/new-go-shops/basic" element={<NewGoshopsBasic />} />
      <Route path="/new-go-shops/server" element={<NewGoshopsServer />} />
      <Route path="/new-go-shops/front" element={<NewGoshopsFront />} />
      <Route path="/new-go-shops/admin" element={<NewGoshopsAdmin />} />
      <Route
        path="/new-go-shops/recommendations"
        element={<Recommendations />}
      />
      <Route
        path="/new-go-shops/local-front"
        element={<NewGoshopsLocalFront />}
      />
      <Route
        path="/new-go-shops/mobile-app"
        element={<NewGoshopsMobileApp />}
      />
      <Route
        path="/new-go-shops/vendor-app"
        element={<MobileVendor pageTitle="Seller and Driver app" />}
      />
      <Route
        path="/new-go-shops/driver-app"
        element={<MobileVendor />}
      />
      <Route
        path="/new-go-shops/pos-app"
        element={<MobileVendor pageTitle="Pos App" />}
      />
      <Route
        path="/new-go-shops/flutter-sdk"
        element={<NewGoshopsFlutterSDK />}
      />
      <Route
        path="/new-go-shops/local-server"
        element={<NewGoshopsLocalServer />}
      />
      <Route
        path="/new-go-shops/install-on-server"
        element={<NewGoshopsInstallOnServer />}
      />
      <Route
        path="/new-go-shops/mandatory-setup"
        element={<NewGoshopsMandatorySetup />}
      />
      <Route
        path="/new-go-shops/customization"
        element={<NewGoshopsCustomization />}
      />
      <Route
        path="/new-go-shops/mandatory-setup-mobile"
        element={<NewGoshopsMandatorySetupMobile />}
      />
      <Route
        path="/new-go-shops/mandatory-setup-backend"
        element={<NewGoshopsMandatorySetupBackend />}
      />
      <Route
        path="/new-go-shops/payment-installation"
        element={<PaymentInstallation />}
      />
      <Route
        path="/new-go-shops/image-settings"
        element={<NewGoshopsImageSettings />}
      />
      <Route
        path="/new-go-shops/mandatory-setup-vendor"
        element={<NewGoshopsMandatorySetupVendor />}
      />
      <Route
        path="/new-go-shops/mandatory-setup-customer"
        element={<NewGoshopsMandatorySetupCustomer />}
      />
      <Route
        path="/new-go-shops/mandatory-setup-pos"
        element={<NewGoshopsMandatorySetupPos />}
      />
      <Route
        path="/new-go-shops/mandatory-setup-deliveryboy"
        element={<NewGoshopsMandatorySetupDeliveryboy />}
      />
      <Route
        path="/new-go-shops/customization-vendor"
        element={<NewGoshopsCustomizationMobile />}
      />
      <Route
        path="/new-go-shops/customization-deliveryboy"
        element={<CustomizationMobileDelivery />}
      />
      <Route
        path="/new-go-shops/customization-customer"
        element={<NewGoshopsCustomizationMobileCustomer />}
      />
      <Route
        path="/new-go-shops/customization-pos"
        element={<CustomizationMobilePos />}
      />
      <Route
        path="/new-go-shops/customer-app-build-release"
        element={<AppBuildReleaseCustomer />}
      />
      <Route
        path="/new-go-shops/pos-app-build-release"
        element={<AppBuildReleasePos />}
      />
      <Route
        path="/new-go-shops/vendor-app-build-release"
        element={<AppBuildReleaseVendor />}
      />
      <Route
        path="/new-go-shops/deliveryboy-app-build-release"
        element={<AppBuildReleaseDelivery />}
      />
      <Route
        path="/new-go-shops/mandatory-setup-web"
        element={<NewGoshopsMandatorySetupWeb />}
      />
      <Route
        path="/new-go-shops/build-code-and-setup-on-server"
        element={<NewGoshopsBuildCodeAndSetupOnServer />}
      />
      <Route
        path="/new-go-shops/build-code-and-setup-on-server-backend"
        element={<NewGoshopsBuildCodeAndSetupOnServerBackend />}
      />
      <Route
        path="/new-go-shops/update-admin-panel"
        element={<NewGoshopsUpdateAdminPanel />}
      />
      <Route
        path="/new-go-shops/update-app-web"
        element={<NewGoshopsUpdateAppWeb />}
      />
      <Route
        path="/new-go-shops/support-plan"
        element={<NewGoshopsSupportPlan />}
      />
      <Route
        path="/new-go-shops/firebase"
        element={<NewGoshopsFirebaseSetup />}
      />

      <Route
        path="/new-go-shops/troubleshooting-backend"
        element={<TroubleshootingBackend />}
      />
      <Route
        path="/new-go-shops/troubleshooting-admin"
        element={<TroubleshootingAdmin />}
      />
      <Route path="/new-go-shops/update" element={<UpdateFooyman />} />

      <Route
        path="/new-go-shops/firebase-web"
        element={<WebFirebase />}
      />
      <Route
        path="/new-go-shops/firebase-mobile"
        element={<MobileFirebase />}
      />
    </Route>
  );
};

export default NewGoShops;
