import React, { useState } from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Link } from "react-router-dom";

const AppBuildReleaseVendor = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };

  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">App build & release</h1>
      <h3 className="introduction-title">Run/Debug Configurations</h3>
      Put{" "}
      <span className="introduction-step">
        --dart-define-from-file=.env/production.env
      </span>{" "}
      if you want to run the project.
      <SimpleReactLightbox>
        <SRLWrapper>
          <a
            href="/assets/img/demand24/mobile-build-1.png"
            data-fancybox
            rel="nofollow"
          >
            <img
              style={{ width: "500px", maxWidth: "100%" }}
              src="/assets/img/demand24/mobile-build-1.png"
              alt="img"
              className="img-responsive"
            />
          </a>
        </SRLWrapper>
      </SimpleReactLightbox>
      <br />
      <SimpleReactLightbox>
        <SRLWrapper>
          <a
            href="/assets/img/demand24/mobile-build-2.png"
            data-fancybox
            rel="nofollow"
          >
            <img
              style={{ width: "800px", maxWidth: "100%" }}
              src="/assets/img/demand24/mobile-build-2.png"
              alt="img"
              className="img-responsive"
            />
          </a>
        </SRLWrapper>
      </SimpleReactLightbox>
      <h3 className="introduction-title">Build for Android</h3>
      For build apk you can run command:
      <div className="introduction-code mb-4">
        <div>
          <span className="ps-0">
            flutter build apk --dart-define-from-file=.env/production.env
          </span>
          <span
            className={text === 1 ? "bg-success copy" : "copy"}
            onClick={() =>
              copyToClipBoard(
                "flutter build apk --dart-define-from-file=.env/production.env",
                1
              )
            }
          >
            {text === 1 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      For play store app bundle build you can run this command:
      <div className="introduction-code mb-4">
        <div>
          <span className="ps-0">
            flutter build appbundle --dart-define-from-file=.env/production.env
          </span>
          <span
            className={text === 2 ? "bg-success copy" : "copy"}
            onClick={() =>
              copyToClipBoard(
                "flutter build appbundle --dart-define-from-file=.env/production.env",
                2
              )
            }
          >
            {text === 2 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      Build file location:
      <span className="introduction-step"> /build/app/outputs/apk/</span>
      For deploying it please follow this documentation:
      <strong className="strong">
        https://docs.flutter.dev/deployment/android
      </strong>
      <h4 className="introduction-contentTitle">Build for iOS</h4>
      There are no general way to generate app for iOS. Apple doesn’t allow to
      install app like this debug way. If you want to install it on your iOS
      device then you have to deploy it on TestFlight or AppStore. For deploying
      it please follow this documentation:
      <strong className="strong">
        https://docs.flutter.dev/deployment/ios
      </strong>
    </div>
  );
};

export default AppBuildReleaseVendor;
