import React from "react";

export const docMenu = [
  {
    name: "Introduction",
    dropDownItems: [
      {
        name: "Introduction",
        routerPath: "/uzmart-documentation/introduction",
      },
      {
        name: "Server requirements",
        routerPath: "/uzmart-documentation/recommendations",
      },
    ],
  },
  {
    name: "Backend Api",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/uzmart-documentation/server",
      },
      {
        name: "Installation",
        routerPath: "/uzmart-documentation/mandatory-setup-backend",
      },
      {
        name: "Payment installation",
        routerPath: "/uzmart-documentation/payment-installation",
      },
      {
        name: "Image settings",
        routerPath: "/uzmart-documentation/image-settings",
      },
      {
        name: "Troubleshooting",
        routerPath: "/uzmart-documentation/troubleshooting-backend",
      },
    ],
  },
  {
    name: "Admin Panel",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/uzmart-documentation/admin",
      },
      {
        name: "Installation",
        routerPath: "/uzmart-documentation/install-on-server",
      },
      {
        name: "Troubleshooting",
        routerPath: "/uzmart-documentation/troubleshooting-admin",
      },
    ],
  },
  {
    name: "Customer Website",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/uzmart-documentation/front",
      },
      {
        name: "Installation",
        routerPath: "/uzmart-documentation/mandatory-setup-web",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Customer App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/uzmart-documentation/mobile-app",
      },
      {
        name: "Installation",
        routerPath: "/uzmart-documentation/mandatory-setup-customer",
      },
      {
        name: "Customization",
        routerPath: "/uzmart-documentation/customization-customer",
      },
      {
        name: "App build & release",
        routerPath: "/uzmart-documentation/customer-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Vendor and Driver App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/uzmart-documentation/vendor-app",
      },
      {
        name: "Installation",
        routerPath: "/uzmart-documentation/mandatory-setup-vendor",
      },
      {
        name: "Customization",
        routerPath: "/uzmart-documentation/customization-vendor",
      },
      {
        name: "App build & release",
        routerPath: "/uzmart-documentation/vendor-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  // {
  //   name: "Driver App",
  //   dropDownItems: [
  //     {
  //       name: "Requirements",
  //       routerPath: "/uzmart-documentation/driver-app",
  //     },
  //     {
  //       name: "Installation",
  //       routerPath: "/uzmart-documentation/mandatory-setup-deliveryboy",
  //     },
  //     {
  //       name: "Customization",
  //       routerPath: "/uzmart-documentation/customization-deliveryboy",
  //     },
  //     {
  //       name: "App build & release",
  //       routerPath: "/uzmart-documentation/deliveryboy-app-build-release",
  //     },
  //     // {
  //     //   name: "Troubleshooting",
  //     //   routerPath: "/",
  //     // },
  //   ],
  // },
  {
    name: "Pos App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/uzmart-documentation/pos-app",
      },
      {
        name: "Installation",
        routerPath: "/uzmart-documentation/mandatory-setup-pos",
      },
      {
        name: "Customization",
        routerPath: "/uzmart-documentation/customization-pos",
      },
      {
        name: "App build & release",
        routerPath: "/uzmart-documentation/pos-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Firebase setup",
    dropDownItems: [
      {
        name: "Website",
        routerPath: "/uzmart-documentation/firebase-web",
      },
      {
        name: "Mobile",
        routerPath: "/uzmart-documentation/firebase-mobile",
      }
    ],
    // routerPath: "/uzmart-documentation/firebase",
  },
  {
    name: "Update",
    routerPath: "/uzmart-documentation/update",
  },
];

export const payments = [
  {
    icon: (
        <img
            src={"./assets/img/root/paypal.png"}
            alt="img"
            width="80"
            height="80"
        />
    ),
  },
  {
    icon: (
        <img
            src={"./assets/img/root/stripe.png"}
            alt="img"
            width="80"
            height="80"
        />
    ),
  },
  {
    icon: (
        <img
            src={"./assets/img/root/razorpay.png"}
            alt="img"
            width="80"
            height="80"
        />
    ),
  },
  {
    icon: (
        <img
            src={"./assets/img/root/paystack.jpeg"}
            alt="img"
            width="120"
            height="120"
        />
    ),
  },
  {
    icon: (
        <img
            src={"./assets/img/root/pay-tabs.jpg"}
            alt="img"
            width="120"
            height="120"
        />
    ),
  },
  {
    icon: (
        <img
            src={"./assets/img/root/flw.png"}
            alt="img"
            width="110"
            height="120"
        />
    ),
  },
  {
    icon: (
        <img
            src={"./assets/img/root/mercado-pago.jpg"}
            alt="img"
            width="120"
            height="120"
        />
    ),
  },
  {
    icon: (
        <img
            src={"./assets/img/root/moyasar.png"}
            alt="img"
            width="120"
            height="120"
        />
    ),
  },
  {
    icon: (
        <img
            src={"./assets/img/root/mollie.png"}
            alt="img"
            width="120"
            height="120"
        />
    ),
  },
  {
    icon: (
        <img
            src={"./assets/img/root/maksekeskus.png"}
            alt="img"
            width="120"
            height="120"
        />
    ),
  },
];

