import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

const portfolioMenu = ["All", "Goshops", "Sundaymart", "Foodyman", "Damda"];

const PortfolioFive = ({ content }) => {
  console.log(content);
  return (
    <>
      <Tabs>
        <div className="row">
          <div className="col-lg-7">
            {/* <!--Animated Block--> */}
            <div
              className="ptf-animated-block"
              data-aos="fade"
              data-aos-delay="0"
            >
              <h2 className="text-uppercase fw-semibold">
                Latest <br />
                Projects
              </h2>
            </div>
          </div>
          {/* End .col */}

          <div className="col-lg-5">
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{
                "--ptf-xxl": " 0.625rem",
                "--ptf-lg": "1.875rem",
              }}
            />
            {/* <!--Animated Block--> */}
            <div
              className="ptf-animated-block"
              data-aos="fade"
              data-aos-delay="0"
            >
              <TabList className="ptf-filters ptf-filters--style-4">
                {portfolioMenu.map((item, i) => (
                  <Tab className="filter-item filter-item-active" key={i}>
                    {item}
                  </Tab>
                ))}
              </TabList>
            </div>
          </div>
          {/* End .col */}
        </div>

        {content.map((item, i) => (
          <TabPanel key={i}>
            <div className="row mt-5" data-aos="fade" data-aos-delay="2">
              {item.tabContent.map((data, id) => (
                <article
                  key={id}
                  className="ptf-work ptf-work--style-2 col-lg-4 g-5"
                >
                  <div className="ptf-work__media">
                    <Link to={data?.link} className="ptf-work__link" />
                    <img
                      src={`assets/img/project/${data?.img}`}
                      alt={data?.title}
                      className="rounded"
                      loading="lazy"
                    />
                  </div>

                  <div className="ptf-work__meta">
                    <div className="ptf-work__category portfolio-text">
                      {data?.description}
                    </div>
                    <h4 className="ptf-work__title">
                      <Link to={data?.link}>{data?.title}</Link>
                    </h4>
                  </div>
                </article>
              ))}
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
};

export default PortfolioFive;
