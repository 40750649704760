import React, { useState } from "react";
import { Link } from "react-router-dom";

const MandatorySetupWeb = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Mandatory Setup Web</h1>
      • Download the code from codecayon <br />
      • Extract the zip files <br />
      • Upload the front folder to your server <br />
      • Install pm2 globally on your server <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn add pm2 -g</span>
          <span
            className={text === 1 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn add pm2 -g", 1)}
          >
            {text === 1 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <br />
      • Install required package <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn</span>
          <span
            className={text === 3 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn", 3)}
          >
            {text === 3 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <br />
      • Build frontend using following command <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn build</span>
          <span
            className={text === 5 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn build", 5)}
          >
            {text === 5 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <br />
      • Run project with pm2 <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">pm2 start "yarn start"</span>
          <span
            className={text === 6 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn start", 6)}
          >
            {text === 6 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <h5 className="introduction-contentTitle"> How to setup web</h5>
      <span className="youtube-blog">
        <iframe
          src="https://www.youtube.com/embed/ocdDqJzMbDM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </span>
      <div className="center-page-container">
        <Link to="/sundaymart-documentation/front" className="btn  previous">
          <p>Previous</p>
          <p className="link"> Requirements </p>
        </Link>
        <Link
          to="/sundaymart-documentation/change-static-data"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Change static data </p>
        </Link>
      </div>
    </div>
  );
};

export default MandatorySetupWeb;
