import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import { docMenu } from "../../fake-data/New-go-shops-menu.js";

const DocumentationMenu = () => {
  return (
    <div className="docMenu">
      <ProSidebar className="pro-sidebar">
        <SidebarContent>
          <Menu className="sidebar-menu_wrapper">
            {docMenu.map((item, i) => (
              <div key={i}>
                {item.dropDownItems?.length > 0 ? (
                  <SubMenu title={item.name} key={i} defaultOpen={true}>
                    {item.dropDownItems?.map((val, index) => (
                      <MenuItem key={index}>
                        <NavLink
                          className="nav-link-active"
                          to={val.routerPath}
                        >
                          {val.name}
                        </NavLink>
                      </MenuItem>
                    ))}
                  </SubMenu>
                ) : (
                  <MenuItem className="sidebar-menu">
                    <NavLink to={item.routerPath} className="nav-link-active">
                      {item.name}
                    </NavLink>
                  </MenuItem>
                )}
              </div>
            ))}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default DocumentationMenu;
