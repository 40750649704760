import React from "react";
import { GiCampfire } from "react-icons/gi";
import NavigationBtns from "./Navigation-btns";

const Recommendations = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Recommendations</h1>
      • Operating system: Ubuntu 22.04 64bit <br />
      • Cores: 4 <br />
      • RAM: 4GB <br />
      • VPS only, shared hosting servers are not recommended. <br />
      <div className="mt-4" />
      <div className="mt-4" />
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        Please do this very carefully. We bear no responsibility for your
        mistake.
      </div>
      <NavigationBtns />
    </div>
  );
};

export default Recommendations;
