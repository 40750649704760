import React from "react";
import { AiOutlineSwapRight } from "react-icons/ai";

const OnePage = ({
  skillContent,
  title,
  subTitle,
  descriptions,
  buy_link,
  img_url,
  button_text = "Buy Now",
}) => {
  return (
    <div className="container-xxl">
      <div className="row align-items-center">
        <div className="col-xl-6">
          <div
            className="ptf-spacer"
            style={{ "--ptf-xxl": "2.5rem", "--ptf-md": "1.25rem" }}
          />
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="100"
          >
            <h2 className="large-heading fw-semibold ">
              <span style={{ color: "green" }}>{title}</span> - {subTitle}
            </h2>
          </div>
          <div
            className="ptf-spacer"
            style={{
              "--ptf-xxl": "4.375rem",
              "--ptf-md": "2.1875rem",
            }}
          />
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="200"
          >
            <div
              className="ptf-animated-block"
              data-aos="fade"
              data-aos-delay="0"
            >
              <p className="fz-18 mb-3 has-white-color">{descriptions}</p>
            </div>
          </div>

          <a target="_blank" href={buy_link}>
            <button className="outlined-btn mb-3">
              {button_text} <AiOutlineSwapRight size={30} />
            </button>
          </a>

          <div className="hero-modern mt-3">
            <ul>
              {skillContent.map((item, i) => (
                <li
                  key={i + item?.title}
                  data-aos="fade-right"
                  data-aos-delay={item.delayAnimation}
                >
                  <a
                    target={item.target}
                    href={item?.link}
                    className="has-white-color"
                  >
                    {item?.icon}
                    {item?.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="col-xl-6" data-aos="fade" data-aos-delay="100">
          <div className="mt-sm-5"></div>
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="400"
          >
            <div className="text-center">
              <div className="ptf-mask-image">
                <img
                  src={`./assets/img/project/${img_url}`}
                  alt="layer"
                  loading="lazy"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnePage;
