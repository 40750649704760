import React, { useState } from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

function PaymentSetUp(props) {
  const [text, setText] = useState(null);
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Payment set up</h1>
      <h4 className="introduction-contentTitle">Payfast credentials</h4>
      You need to put <strong>payfast</strong> keys here.
      <div className="introduction-code">
        <p>/.env/production.env</p>
        <hr />

        <div>
          <span className="ps-0">
            PAYFAST_PASSPHRASE=
            <span className="text-black">'PASSPHRASE'</span>
          </span>
          <span
            className={text === 4 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("PAYFAST_PASSPHRASE=PASSPHRASE", 4)}
          >
            {text === 4 ? "copied!" : "copy"}
          </span>
        </div>
        <div>
          <span className="ps-0">
            PAYFAST_MERCHANT_ID =
            <span className="text-black">'MERCHANT_ID'</span>
          </span>
          <span
            className={text === 4 ? "bg-success copy" : "copy"}
            onClick={() =>
              copyToClipBoard("PAYFAST_MERCHANT_ID=MERCHANT_ID", 4)
            }
          >
            {text === 4 ? "copied!" : "copy"}
          </span>
        </div>
        <div>
          <span className="ps-0">
            PAYFAST_MERCHANT_KEY =
            <span className="text-black">'MERCHANT_KEY'</span>
          </span>
          <span
            className={text === 4 ? "bg-success copy" : "copy"}
            onClick={() =>
              copyToClipBoard("PAYFAST_MERCHANT_KEY=MERCHANT_KEY", 4)
            }
          >
            {text === 4 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <h4 className="introduction-contentTitle">Other payment credentials</h4>
      Other payment methods are added on the page of the admin panel shown in
      the image below
      <SimpleReactLightbox>
        <SRLWrapper>
          <a
            href="/assets/img/demand24/payment-payload.png"
            data-fancybox
            rel="nofollow"
          >
            <img
              src="/assets/img/demand24/payment-payload.png"
              alt="img"
              className="img-responsive"
            />
          </a>
        </SRLWrapper>
      </SimpleReactLightbox>
      <NavigationBtns />
    </div>
  );
}

export default PaymentSetUp;
